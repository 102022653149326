import React from 'react';
import "./SectionThree.scss";
import twelveX from '../../Images/12x.png'
import nineteenX from '../../Images/19x.png'
export default function SectionThree() {
    return (
        <div className="mainWrapper">
            <div className="sectionThree">
            <h1>Meet the Fleet</h1>
            <div className="two-columns">
            <section>
                    <h2>12-X</h2>
                    <p>Clean in hard to reach areas with this small-footprint walk behind floor scrubber. Especially agile and quiet for congested areas.</p>
                    <img src={twelveX} alt="12X" />
                </section>
                <section>
                    <h2>19-X</h2>
                    <p>Additional productivity and capacity on a mid-sized walk behind scrubber. Pass easily through small doorways or elevators, with this versatile and durable platform.</p>
                    <img src={nineteenX} alt="12X" />
                </section>
            </div>
            </div>
        </div>
    )
}
