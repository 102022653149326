import React from 'react';
import "./SectionOne.scss";
import header from '../../Images/header.png';

export default function SectionOne() {
    function scrolltoform(e) {
        e.preventDefault()
        console.log("Clicked")
        let request = document.getElementById('request')
        request.scrollIntoView({ behavior: `smooth`})
    } 
    return (
        <>
        <div className="mainWrapper">
            <div className="sectionOne">
            <h3>Made in the USA, Built For Tough Jobs</h3>
            <button onClick={scrolltoform}>Buy Online Now</button>
            </div>
        </div>
        <div className="header">
            <img src={header} className="headerImg" alt="header" />
        </div>
        </>
    )
}
