import React from 'react'

import SectionOne from './Index/SectionOne/SectionOne'
import SectionTwo from './Index/SectionTwo/SectionTwo'
import SectionThree from './Index/SectionThree/SectionThree'
import SectionFour from './Index/SectionFour/SectionFour'
import MobileNavigation from './Navigation/MobileNavigation'
import ScrollToTop from './ScrollToTop'
import Footer from './Footer/Footer'
import "./style.scss"
import {
  BrowserRouter as Router,

} from "react-router-dom";
import Splash from './SplashPage/Splash'
export default function App() {
  return (
    <Router>
    <ScrollToTop />
    <MobileNavigation />
    <SectionOne />
    <SectionTwo />
    <SectionThree />
    <SectionFour /> */}
    {/* {/* <Footer /> */}
    {/* SIte under development */}
    {/* <Splash /> */}
    </Router>
  )
}
