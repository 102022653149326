import React from 'react'
import "./SectionTwo.scss"
import CutAway from '../../Images/cutaway.png'
import Squeeg from '../../Images/squeegee.png'
import Vac from '../../Images/vac.png'
import Frame from '../../Images/frame.png'
import Actuator from '../../Images/actuator.png'
export default function SectionTwo() {
    return (
        <div className="mainWrapper">
            <div className="sectionTwo">
                <h1>Why Big Ox</h1>
                <div className="two-columns">
                <section>
                        <div>
                        <h3>Tough By Design</h3>
                            <img src={CutAway} alt="cutaway" />
                            <p>
                            Redundant Vac Protection Systems keep you scrubbing, not on the phone with the service tech.
                            </p>
                            <ol>
                                <li>Screw Off Lid For Clean Out Port.</li>
                                <li>Recovery Splash Guards</li>
                                <li>Stainless Float Ball Screen</li>
                                <li>White Filter</li>
                                <li>Fine Mesh Screen</li>
                                <li>Easy Front Fill Port With Steel Basket and Faucet Fill Hose Extension</li>
                            </ol>
                    </div>
                </section>
                <div className="two-columns-two">
                <section>
                <h3>Steel Frame Support</h3>
                <img src ={Frame} alt="frame" />
                <p>We through bolt these assemblies to the steel frame, not the tanks. No tapped steel that could rust in time</p>
                </section>
                <section>
                <h3>Toolless Squeegee</h3>
                <img src={Squeeg} alt="Squeegee" />
                <p>This squeegee is specially designed for speedy toolless assembly. It is held together by easy-snap clips which make changing a squeegee blade a breeze with less down time.</p>
                </section>
                <section>
                    <h3>Vac Protection</h3>
                    <img src={Vac} alt="vac" />
                    <p>
                    Passive alert system will show you if you have over filled your machine and passed moisture through the vac motor. Water collects and drips from the molded in solution tank funnel and flows from the blue hose.
                    </p>
                </section>
                <section>
                    <h3>Powerful Actuator</h3>
                    <img src={Actuator} alt="actuator" />
                    <p>250lb sealed actuator delivers immense down pressure to help clean even the most stubborn floors and spills.</p>
                </section>
                </div>
                </div>



            </div>
        </div>
    )
}
