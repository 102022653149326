import React, { useState } from 'react';
import "./MobileNavigation.scss";
import logo from "../Images/bigoxnav.png";
import { FiMenu, FiX } from "react-icons/fi"

export default function MobileNavigation() {
    const [changeIcon, setChangeIcon] = useState(false)
    const [showMenu, setShowMenu] = useState(false)
    const handleClick = (e) => {
        e.preventDefault()
        console.log("Clicked")
        let request = document.getElementById('request')
        request.scrollIntoView({ behavior: `smooth`})
            setShowMenu(false)
            setChangeIcon(false)
    }
    const toggleMenu = () => {
        if(showMenu) {
            setShowMenu(false)
            setChangeIcon(false)
        } else 
        {
            setShowMenu(true)
            setChangeIcon(true)
        }
    }
    return (
        <nav className="mobile-nav">
            <div><img src={logo} alt="logo"></img></div>
            <div><button onClick={handleClick} className="buyMe">Buy It Now</button>{!changeIcon ? <FiMenu onClick={toggleMenu} className="svgButton"/> : <FiX onClick={toggleMenu}/>}</div>
            <div className={`mobile-dropdown-menu ${showMenu ? ` show` : ` hide`}`}>
                <ul>
                <li><button onClick={handleClick}>Buy It Now</button></li>
                {/* <li><button to="#">Accessories</button></li>
                <li><button to="#">Accessories</button></li> */}
                </ul>
            </div>

        </nav>
    )
}

