import React, { useRef, useState } from 'react'
import './SectionFour.scss'
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init("user_3WEde24MIRgqKFZJTQpnQ");

export default function SectionFour() {
    // EmailJS
    const USER_ID = `user_3WEde24MIRgqKFZJTQpnQ`
    // const TOKEN = `3a2a2f212f6b3eef55611ecdf281e7c5`
    const SERV_ID = `service_mp1bmgc`
    const TEMP_ID = `template_nymrx4o`
    const REPL_ID = `template_fq9t51y`
    const requestRef = useRef()
    const [disable, setDisable] = useState(false)



    function sendEmail(e) {
        e.preventDefault();
        setDisable(true)
        let firstname = e.target.firstname.value
        let lastname = e.target.lastname.value
        let email = e.target.email.value
        let phone = e.target.phone.value
        let model = e.target.model.value
        let sqft = e.target.model.value
        let form = document.getElementById('request')
        if (firstname !== "" && lastname !== "" && email !== "" && phone !== "" && sqft !== "") {
            emailjs.sendForm(`${SERV_ID}`, `${REPL_ID}`, e.target, `${USER_ID}`).then((result) => {
                console.log(result)
            })
            emailjs.sendForm(`${SERV_ID}`, `${TEMP_ID}`, e.target, `${USER_ID}`)
            .then((result) => {
                alert('Thank you for your interest, I sales representative will be in touch.')
                form.reset()
                setDisable(false)
            }, (error) => {
                console.log(error.text);
            });
            } else {
                alert(`One or more fields needs filled out`)
                setDisable(false)
            }
      }

    return (
        <div className="mainWrapper">
            <div className="sectionFour">
            <h1>Buy Online Now</h1>
            <h3>Fill out this contact form and we will reach out right away to process your order</h3>
            <p className="hint">Fields marked with an asterisk (*) are required.</p>
            <form id="request" onSubmit={sendEmail} ref={requestRef}>
            <label>First name * </label>
            <input type="text" name="firstname"></input>
            <label>Last name *</label>
            <input type="text" name="lastname"></input>
            <label>Email *</label>
            <input type="email" name="email"></input>
            <label>Phone Number </label>
            <input type="phone" name="phone"></input>
            <label>Machine Model(12-X or 19-X)</label>
            <input type="text" name="model" defaultValue="12X / 19X"></input>
            <label>Building Sq. Ft. * </label>
            <input type="text" name="sqft"></input>
            {disable ? "" : <button type="submit">Purchase Request</button>}
            </form>
            </div>
        </div>
    )
}
